import { User } from '#app/lib/firebase/models/user.js';

export type Country = {
  code: string;
  value: string;
};

/**
 * List of Countries as ISO 3166-1 alpha-2 code
 * Source: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements
 */
const countries: Array<Country> = [
  {
    code: 'AD',
    value: 'Andorra'
  },
  {
    code: 'AE',
    value: 'United Arab Emirates'
  },
  {
    code: 'AF',
    value: 'Afghanistan'
  },
  {
    code: 'AG',
    value: 'Antigua and Barbuda'
  },
  {
    code: 'AI',
    value: 'Anguilla'
  },
  {
    code: 'AL',
    value: 'Albania'
  },
  {
    code: 'AM',
    value: 'Armenia'
  },
  {
    code: 'AO',
    value: 'Angola'
  },
  {
    code: 'AQ',
    value: 'Antarctica'
  },
  {
    code: 'AR',
    value: 'Argentina'
  },
  {
    code: 'AS',
    value: 'American Samoa'
  },
  {
    code: 'AT',
    value: 'Austria'
  },
  {
    code: 'AU',
    value: 'Australia'
  },
  {
    code: 'AW',
    value: 'Aruba'
  },
  {
    code: 'AX',
    value: 'Åland Islands'
  },
  {
    code: 'AZ',
    value: 'Azerbaijan'
  },
  {
    code: 'BA',
    value: 'Bosnia and Herzegovina'
  },
  {
    code: 'BB',
    value: 'Barbados'
  },
  {
    code: 'BD',
    value: 'Bangladesh'
  },
  {
    code: 'BE',
    value: 'Belgium'
  },
  {
    code: 'BF',
    value: 'Burkina Faso'
  },
  {
    code: 'BG',
    value: 'Bulgaria'
  },
  {
    code: 'BH',
    value: 'Bahrain'
  },
  {
    code: 'BI',
    value: 'Burundi'
  },
  {
    code: 'BJ',
    value: 'Benin'
  },
  {
    code: 'BL',
    value: 'Saint Barthélemy'
  },
  {
    code: 'BM',
    value: 'Bermuda'
  },
  {
    code: 'BN',
    value: 'Brunei Darussalam'
  },
  {
    code: 'BO',
    value: 'Bolivia'
  },
  // {
  //   code: 'BQ',
  //   value: 'Bonaire, Sint Eustatius and Saba'
  // },
  {
    code: 'BR',
    value: 'Brazil'
  },
  {
    code: 'BS',
    value: 'Bahamas'
  },
  {
    code: 'BT',
    value: 'Bhutan'
  },
  {
    code: 'BV',
    value: 'Bouvet Island'
  },
  {
    code: 'BW',
    value: 'Botswana'
  },
  {
    code: 'BY',
    value: 'Belarus'
  },
  {
    code: 'BZ',
    value: 'Belize'
  },
  {
    code: 'CA',
    value: 'Canada'
  },
  {
    code: 'CC',
    value: 'Cocos Islands'
  },
  {
    code: 'CD',
    value: 'Congo'
  },
  {
    code: 'CF',
    value: 'Central African Republic'
  },
  {
    code: 'CG',
    value: 'Congo'
  },
  {
    code: 'CH',
    value: 'Switzerland'
  },
  {
    code: 'CI',
    value: "Côte d'Ivoire"
  },
  {
    code: 'CK',
    value: 'Cook Islands'
  },
  {
    code: 'CL',
    value: 'Chile'
  },
  {
    code: 'CM',
    value: 'Cameroon'
  },
  {
    code: 'CN',
    value: 'China'
  },
  {
    code: 'CO',
    value: 'Colombia'
  },
  {
    code: 'CR',
    value: 'Costa Rica'
  },
  {
    code: 'CU',
    value: 'Cuba'
  },
  {
    code: 'CV',
    value: 'Cabo Verde'
  },
  {
    code: 'CW',
    value: 'Curaçao'
  },
  {
    code: 'CX',
    value: 'Christmas Island'
  },
  {
    code: 'CY',
    value: 'Cyprus'
  },
  {
    code: 'CZ',
    value: 'Czechia'
  },
  {
    code: 'DE',
    value: 'Germany'
  },
  {
    code: 'DJ',
    value: 'Djibouti'
  },
  {
    code: 'DK',
    value: 'Denmark'
  },
  {
    code: 'DM',
    value: 'Dominica'
  },
  {
    code: 'DO',
    value: 'Dominican Republic'
  },
  {
    code: 'DZ',
    value: 'Algeria'
  },
  {
    code: 'EC',
    value: 'Ecuador'
  },
  {
    code: 'EE',
    value: 'Estonia'
  },
  {
    code: 'EG',
    value: 'Egypt'
  },
  {
    code: 'EH',
    value: 'Western Sahara'
  },
  {
    code: 'ER',
    value: 'Eritrea'
  },
  {
    code: 'ES',
    value: 'Spain'
  },
  {
    code: 'ET',
    value: 'Ethiopia'
  },
  {
    code: 'FI',
    value: 'Finland'
  },
  {
    code: 'FJ',
    value: 'Fiji'
  },
  {
    code: 'FK',
    value: 'Falkland Islands'
  },
  {
    code: 'FM',
    value: 'Micronesia'
  },
  {
    code: 'FO',
    value: 'Faroe Islands'
  },
  {
    code: 'FR',
    value: 'France'
  },
  {
    code: 'GA',
    value: 'Gabon'
  },
  {
    code: 'GB',
    value: 'United Kingdom'
  },
  {
    code: 'GD',
    value: 'Grenada'
  },
  {
    code: 'GE',
    value: 'Georgia'
  },
  {
    code: 'GF',
    value: 'French Guiana'
  },
  {
    code: 'GG',
    value: 'Guernsey'
  },
  {
    code: 'GH',
    value: 'Ghana'
  },
  {
    code: 'GI',
    value: 'Gibraltar'
  },
  {
    code: 'GL',
    value: 'Greenland'
  },
  {
    code: 'GM',
    value: 'Gambia'
  },
  {
    code: 'GN',
    value: 'Guinea'
  },
  {
    code: 'GP',
    value: 'Guadeloupe'
  },
  {
    code: 'GQ',
    value: 'Equatorial Guinea'
  },
  {
    code: 'GR',
    value: 'Greece'
  },
  // {
  //   code: 'GS',
  //   value: 'South Georgia and the South Sandwich Islands'
  // },
  {
    code: 'GT',
    value: 'Guatemala'
  },
  {
    code: 'GU',
    value: 'Guam'
  },
  {
    code: 'GW',
    value: 'Guinea-Bissau'
  },
  {
    code: 'GY',
    value: 'Guyana'
  },
  {
    code: 'HK',
    value: 'Hong Kong'
  },
  // {
  //   code: 'HM',
  //   value: 'Heard Island and McDonald Islands'
  // },
  {
    code: 'HN',
    value: 'Honduras'
  },
  {
    code: 'HR',
    value: 'Croatia'
  },
  {
    code: 'HT',
    value: 'Haiti'
  },
  {
    code: 'HU',
    value: 'Hungary'
  },
  {
    code: 'ID',
    value: 'Indonesia'
  },
  {
    code: 'IE',
    value: 'Ireland'
  },
  {
    code: 'IL',
    value: 'Israel'
  },
  {
    code: 'IM',
    value: 'Isle of Man'
  },
  {
    code: 'IN',
    value: 'India'
  },
  // {
  //   code: 'IO',
  //   value: 'British Indian Ocean Territory'
  // },
  {
    code: 'IQ',
    value: 'Iraq'
  },
  {
    code: 'IR',
    value: 'Iran'
  },
  {
    code: 'IS',
    value: 'Iceland'
  },
  {
    code: 'IT',
    value: 'Italy'
  },
  {
    code: 'JE',
    value: 'Jersey'
  },
  {
    code: 'JM',
    value: 'Jamaica'
  },
  {
    code: 'JO',
    value: 'Jordan'
  },
  {
    code: 'JP',
    value: 'Japan'
  },
  {
    code: 'KE',
    value: 'Kenya'
  },
  {
    code: 'KG',
    value: 'Kyrgyzstan'
  },
  {
    code: 'KH',
    value: 'Cambodia'
  },
  {
    code: 'KI',
    value: 'Kiribati'
  },
  {
    code: 'KM',
    value: 'Comoros'
  },
  {
    code: 'KN',
    value: 'Saint Kitts and Nevis'
  },
  {
    code: 'KP',
    value: 'Korea DPR'
  },
  {
    code: 'KR',
    value: 'Korea, Republic of'
  },
  {
    code: 'KW',
    value: 'Kuwait'
  },
  {
    code: 'KY',
    value: 'Cayman Islands'
  },
  {
    code: 'KZ',
    value: 'Kazakhstan'
  },
  {
    code: 'LA',
    value: 'Lao PDR'
  },
  {
    code: 'LB',
    value: 'Lebanon'
  },
  {
    code: 'LC',
    value: 'Saint Lucia'
  },
  {
    code: 'LI',
    value: 'Liechtenstein'
  },
  {
    code: 'LK',
    value: 'Sri Lanka'
  },
  {
    code: 'LR',
    value: 'Liberia'
  },
  {
    code: 'LS',
    value: 'Lesotho'
  },
  {
    code: 'LT',
    value: 'Lithuania'
  },
  {
    code: 'LU',
    value: 'Luxembourg'
  },
  {
    code: 'LV',
    value: 'Latvia'
  },
  {
    code: 'LY',
    value: 'Libya'
  },
  {
    code: 'MA',
    value: 'Morocco'
  },
  {
    code: 'MC',
    value: 'Monaco'
  },
  {
    code: 'MD',
    value: 'Moldova'
  },
  {
    code: 'ME',
    value: 'Montenegro'
  },
  // {
  //   code: 'MF',
  //   value: 'Saint Martin (French part)'
  // },
  {
    code: 'MG',
    value: 'Madagascar'
  },
  {
    code: 'MH',
    value: 'Marshall Islands'
  },
  {
    code: 'MK',
    value: 'North Macedonia'
  },
  {
    code: 'ML',
    value: 'Mali'
  },
  {
    code: 'MM',
    value: 'Myanmar'
  },
  {
    code: 'MN',
    value: 'Mongolia'
  },
  {
    code: 'MO',
    value: 'Macao'
  },
  // {
  //   code: 'MP',
  //   value: 'Northern Mariana Islands'
  // },
  {
    code: 'MQ',
    value: 'Martinique'
  },
  {
    code: 'MR',
    value: 'Mauritania'
  },
  {
    code: 'MS',
    value: 'Montserrat'
  },
  {
    code: 'MT',
    value: 'Malta'
  },
  {
    code: 'MU',
    value: 'Mauritius'
  },
  {
    code: 'MV',
    value: 'Maldives'
  },
  {
    code: 'MW',
    value: 'Malawi'
  },
  {
    code: 'MX',
    value: 'Mexico'
  },
  {
    code: 'MY',
    value: 'Malaysia'
  },
  {
    code: 'MZ',
    value: 'Mozambique'
  },
  {
    code: 'NA',
    value: 'Namibia'
  },
  {
    code: 'NC',
    value: 'New Caledonia'
  },
  {
    code: 'NE',
    value: 'Niger'
  },
  {
    code: 'NF',
    value: 'Norfolk Island'
  },
  {
    code: 'NG',
    value: 'Nigeria'
  },
  {
    code: 'NI',
    value: 'Nicaragua'
  },
  {
    code: 'NL',
    value: 'Netherlands'
  },
  {
    code: 'NO',
    value: 'Norway'
  },
  {
    code: 'NP',
    value: 'Nepal'
  },
  {
    code: 'NR',
    value: 'Nauru'
  },
  {
    code: 'NU',
    value: 'Niue'
  },
  {
    code: 'NZ',
    value: 'New Zealand'
  },
  {
    code: 'OM',
    value: 'Oman'
  },
  {
    code: 'PA',
    value: 'Panama'
  },
  {
    code: 'PE',
    value: 'Peru'
  },
  {
    code: 'PF',
    value: 'French Polynesia'
  },
  {
    code: 'PG',
    value: 'Papua New Guinea'
  },
  {
    code: 'PH',
    value: 'Philippines'
  },
  {
    code: 'PK',
    value: 'Pakistan'
  },
  {
    code: 'PL',
    value: 'Poland'
  },
  {
    code: 'PM',
    value: 'Saint Pierre and Miquelon'
  },
  {
    code: 'PN',
    value: 'Pitcairn'
  },
  {
    code: 'PR',
    value: 'Puerto Rico'
  },
  {
    code: 'PS',
    value: 'Palestine, State of'
  },
  {
    code: 'PT',
    value: 'Portugal'
  },
  {
    code: 'PW',
    value: 'Palau'
  },
  {
    code: 'PY',
    value: 'Paraguay'
  },
  {
    code: 'QA',
    value: 'Qatar'
  },
  {
    code: 'RE',
    value: 'Réunion'
  },
  {
    code: 'RO',
    value: 'Romania'
  },
  {
    code: 'RS',
    value: 'Serbia'
  },
  {
    code: 'RU',
    value: 'Russian Federation'
  },
  {
    code: 'RW',
    value: 'Rwanda'
  },
  {
    code: 'SA',
    value: 'Saudi Arabia'
  },
  {
    code: 'SB',
    value: 'Solomon Islands'
  },
  {
    code: 'SC',
    value: 'Seychelles'
  },
  {
    code: 'SD',
    value: 'Sudan'
  },
  {
    code: 'SE',
    value: 'Sweden'
  },
  {
    code: 'SG',
    value: 'Singapore'
  },
  // {
  //   code: 'SH',
  //   value: 'Saint Helena, Ascension and Tristan da Cunha'
  // },
  {
    code: 'SI',
    value: 'Slovenia'
  },
  // {
  //   code: 'SJ',
  //   value: 'Svalbard and Jan Mayen'
  // },
  {
    code: 'SK',
    value: 'Slovakia'
  },
  {
    code: 'SL',
    value: 'Sierra Leone'
  },
  {
    code: 'SM',
    value: 'San Marino'
  },
  {
    code: 'SN',
    value: 'Senegal'
  },
  {
    code: 'SO',
    value: 'Somalia'
  },
  {
    code: 'SR',
    value: 'Suriname'
  },
  {
    code: 'SS',
    value: 'South Sudan'
  },
  {
    code: 'ST',
    value: 'Sao Tome and Principe'
  },
  {
    code: 'SV',
    value: 'El Salvador'
  },
  {
    code: 'SX',
    value: 'Sint Maarten (Dutch part)'
  },
  {
    code: 'SY',
    value: 'Syrian Arab Republic'
  },
  {
    code: 'SZ',
    value: 'Eswatini'
  },
  {
    code: 'TC',
    value: 'Turks and Caicos Islands'
  },
  {
    code: 'TD',
    value: 'Chad'
  },
  // {
  //   code: 'TF',
  //   value: 'French Southern Territories'
  // },
  {
    code: 'TG',
    value: 'Togo'
  },
  {
    code: 'TH',
    value: 'Thailand'
  },
  {
    code: 'TJ',
    value: 'Tajikistan'
  },
  {
    code: 'TK',
    value: 'Tokelau'
  },
  {
    code: 'TL',
    value: 'Timor-Leste'
  },
  {
    code: 'TM',
    value: 'Turkmenistan'
  },
  {
    code: 'TN',
    value: 'Tunisia'
  },
  {
    code: 'TO',
    value: 'Tonga'
  },
  {
    code: 'TR',
    value: 'Türkiye'
  },
  {
    code: 'TT',
    value: 'Trinidad and Tobago'
  },
  {
    code: 'TV',
    value: 'Tuvalu'
  },
  {
    code: 'TW',
    value: 'Taiwan'
  },
  {
    code: 'TZ',
    value: 'Tanzania'
  },
  {
    code: 'UA',
    value: 'Ukraine'
  },
  {
    code: 'UG',
    value: 'Uganda'
  },
  // {
  //   code: 'UM',
  //   value: 'United States Minor Outlying Islands'
  // },
  {
    code: 'US',
    value: 'USA'
  },
  {
    code: 'UY',
    value: 'Uruguay'
  },
  {
    code: 'UZ',
    value: 'Uzbekistan'
  },
  {
    code: 'VA',
    value: 'Holy See'
  },
  {
    code: 'VC',
    value: 'Saint Vincent and the Grenadines'
  },
  {
    code: 'VE',
    value: 'Venezuela'
  },
  {
    code: 'VG',
    value: 'Virgin Islands (British)'
  },
  {
    code: 'VI',
    value: 'Virgin Islands (U.S.)'
  },
  {
    code: 'VN',
    value: 'Viet Nam'
  },
  {
    code: 'VU',
    value: 'Vanuatu'
  },
  {
    code: 'WF',
    value: 'Wallis and Futuna'
  },
  {
    code: 'WS',
    value: 'Samoa'
  },
  {
    code: 'YE',
    value: 'Yemen'
  },
  {
    code: 'YT',
    value: 'Mayotte'
  },
  {
    code: 'ZA',
    value: 'South Africa'
  },
  {
    code: 'ZM',
    value: 'Zambia'
  },
  {
    code: 'ZW',
    value: 'Zimbabwe'
  }
];

export const getCountryFullName = (countryCode?: String) => {
  if (!countryCode) {
    return '';
  }

  const country = countries.find(x => x.code === countryCode);

  return country ? country.value : '';
};

export const sortCountriesByName = (countryA: Country, countryB: Country) => {
  if (!countryA.value || !countryB.value) {
    return 0;
  }

  if (countryA.value < countryB.value) {
    return -1;
  }
  if (countryA.value > countryB.value) {
    return 1;
  }

  // names must be equal
  return 0;
};

export default countries;
